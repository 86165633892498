import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import Apartment from '@mui/icons-material/Apartment';
import Business from '@mui/icons-material/Business';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Help from '@mui/icons-material/Help';
import Home from '@mui/icons-material/Home';
import Logout from '@mui/icons-material/Logout';
import People from '@mui/icons-material/People';
import Settings from '@mui/icons-material/Settings';
import { CSSObject, styled, Theme } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useParams, usePathname, useRouter } from 'next/navigation';
import React, { PropsWithChildren, ReactNode, useState } from 'react';

import { signout } from '@/app/[lng]/login/actions';
import { useTranslation } from '@/app/i18n/client';
import SmallLogo from '@/components/logos/SmallLogo';
import useAuthStore from '@/stores/auth.store';
import useLoadingPageStore from '@/stores/loadingPage.store';
import { ProfileWithRole } from '@/types/exported.types';
import { useMediaQuery } from '@/utils';

import { CustomerAvatar } from './CustomerAvatar';
import { HelpDialog } from './HelpDialog';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(
  MuiDrawer,
  {}
)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

type Props = {
  profile: ProfileWithRole;
  setIsSettingsOpen: (boolean: boolean) => void;
  open: boolean;
  setOpen: (boolean: boolean) => void | Promise<void>;
};

type MenuItems = {
  title?: string;
  path?: string;
  icon?: ReactNode;
  divider?: boolean;
  logo?: boolean;
  admin?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void | Promise<void>;
};

export const DrawerMenu: React.FC<Props & PropsWithChildren> = ({ profile, setIsSettingsOpen, open, setOpen }) => {
  const setLoading = useLoadingPageStore((state) => state.setLoading);
  const [helpDialogOpen, setHelpDialogOpen] = useState<boolean>(false);
  const [openCustomerMenu, setOpenCustomerMenu] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isBreakpoint600 = useMediaQuery(600);
  const setAuth = useAuthStore((state) => state.setAuth);
  const setProfile = useAuthStore((state) => state.setProfile);
  const pathName = usePathname() ?? '';
  const router = useRouter();
  const params = useParams();
  const lng = params?.lng;
  const { t } = useTranslation(typeof lng === 'string' ? lng : 'en');
  const onCreatePassword = pathName.includes('create-password');
  const menuItems: MenuItems[] = [
    { logo: true, onClick: () => setOpen(!open), icon: open ? <ChevronLeft /> : <ChevronRight /> },
    { divider: true },
    { title: t('home'), path: `/${lng}`, icon: <Home /> },
    { title: t('properties'), path: `/${lng}/properties`, icon: <Apartment /> },
    { title: t('admin'), path: `/${lng}/admin`, icon: <AdminPanelSettings />, admin: true },
    { title: t('users'), path: `/${lng}/users`, icon: <People />, admin: true },
    { title: t('organizations'), path: `/${lng}/organizations`, icon: <Business />, admin: true },
    { divider: true },
    { title: t('profile'), onClick: () => setIsSettingsOpen(true), icon: <Settings /> },
    {
      title: t('logOut'),
      onClick: () => {
        signout();
        setProfile({ profile: null });
        setAuth({ user: null });
      },
      icon: <Logout />,
    },
    { title: t('help'), onClick: () => setHelpDialogOpen(true), icon: <Help /> },
    {
      title: profile.customer?.title,
      onClick: (event) => {
        if (!openCustomerMenu) {
          setAnchorEl(event.currentTarget);
          setOpenCustomerMenu(true);
        }
      },
      icon: (
        <CustomerAvatar
          profileId={profile.id}
          openCustomerMenu={openCustomerMenu}
          setOpenCustomerMenu={setOpenCustomerMenu}
          customerTitle={profile.customer?.title}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      ),
      admin: true,
    },
  ];
  return (
    <Drawer
      anchor="left"
      onClose={() => setOpen(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={{
        sx: { borderRadius: 0, display: onCreatePassword ? 'none' : null },
      }}
      open={open}
      variant={isBreakpoint600 ? 'temporary' : 'permanent'}
    >
      <HelpDialog open={helpDialogOpen} onClose={() => setHelpDialogOpen(false)} />
      <List>
        {menuItems.map((item, index) => {
          if (item.admin && !profile.role?.map((role) => role.title.toUpperCase()).includes('SUPER_ADMIN')) return null;
          if (item.divider) return <Divider key={index} />;
          return (
            <ListItem key={item.path || index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={async (e) => {
                  if (item.path) {
                    if (pathName.split('?')[0] !== item.path.split('?')[0]) {
                      setLoading(true);
                    }
                    if (isBreakpoint600) {
                      setOpen(false);
                    }
                    if (item.onClick) {
                      await item.onClick(e);
                    }
                    router.push(item.path);
                  }
                  if (item.onClick) {
                    await item.onClick(e);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  disableTypography={item.logo}
                  primary={item.logo ? <SmallLogo /> : item.title}
                  sx={{
                    opacity: open ? 1 : 0,
                    display: item.logo ? 'flex' : 'block',
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};
