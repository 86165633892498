'use client';
import MenuIcon from '@mui/icons-material/Menu';
import { NoSsr } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { signout } from '@/app/[lng]/login/actions';
import { languages } from '@/app/i18n/settings';
import { HelpButton } from '@/components/HelpButton';
import { getClientSupabaseClient } from '@/service/supabase.client';
import { getProfileWithRole } from '@/service/users.service';
import useAuthStore from '@/stores/auth.store';
import useLoadingPageStore from '@/stores/loadingPage.store';
import { useMediaQuery } from '@/utils';

import { BreadcrumbsToolbar } from './BreadcrumbsToolbar';
import { DrawerMenu } from './DrawerMenu';
import { LoadingComponent } from './LoadingComponent';
import { LoginPageWrapper } from './LoginPageWrapper';
import SettingsDialog from './SettingsDialog';

export const NavigationWrappper: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useAuthStore((state) => state.user);
  const profile = useAuthStore((state) => state.profile);
  const isBreakpoint600 = useMediaQuery(600);
  const [loadingAuth, setLoadingAuth] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const loading = useLoadingPageStore((state) => state.loading);
  const setLoading = useLoadingPageStore((state) => state.setLoading);
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const router = useRouter();
  const pathName = usePathname() ?? '';
  const setAuth = useAuthStore((state) => state.setAuth);
  const setProfile = useAuthStore((state) => state.setProfile);

  const onCreatePassword = pathName.includes('create-password');

  const searchParams = useSearchParams();
  const token_hash = searchParams?.get('token_hash');

  const loginPage = pathName.split('/')[1] === 'login' || pathName.split('/')[2] === 'login';
  const confirmSignupPage = pathName.split('/')[2] === 'confirm-signup';

  const redirectToPreferredLanguage = (preferredLanguage: string) => {
    const pathLanguage = pathName.split('/')[1];
    const hasLanguageInPath = languages.some((loc) => pathName.startsWith(`/${loc}`)) && pathLanguage.length == 2;

    if (hasLanguageInPath && pathLanguage !== preferredLanguage) {
      const newUrl = pathName.replace(`/${pathLanguage}`, `/${preferredLanguage}`);
      router.push(newUrl);
    }
  };

  useEffect(() => {
    if (!pathName.includes('login') && !token_hash && !user) {
      (async () => {
        try {
          const supabase = getClientSupabaseClient(); // It's very important we don't initialize the supabase client before we're logged in. If we do it'll think the client is anon role for realtime purposes. I spend an entire day figuring this out.
          const {
            data: { user: authUser },
            error: authError,
          } = await supabase.auth.getUser();
          if (authError || !authUser) {
            throw authError;
          } else {
            if (authUser) {
              const { data: profileResponse, error } = await getProfileWithRole(supabase, authUser.id);
              if (error) throw error;
              if (!profileResponse) throw new Error('No profile found');
              setProfile({ profile: profileResponse });
              setAuth({ user: authUser });
              redirectToPreferredLanguage(profileResponse.language);
            }
            setLoadingAuth(false);
          }
        } catch (e) {
          setProfile({ profile: null });
          setAuth({ user: null });
          signout();
          console.log(e);
        }
      })();
    } else if (!pathName.includes('login') || !token_hash) {
      setLoadingAuth(false);
    } else {
      setProfile({ profile: null });
      setAuth({ user: null });
      setLoadingAuth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAuth, setProfile, pathName, user]);

  useEffect(() => {
    setLoading(false);
  }, [pathName, setLoading]);

  let content;

  if (confirmSignupPage) {
    content = children;
  } else if (loginPage) {
    content = <LoginPageWrapper>{children}</LoginPageWrapper>;
  } else if (loadingAuth) {
    content = <LoadingComponent></LoadingComponent>;
  } else {
    content = (
      <Box display={'flex'}>
        <SettingsDialog profile={profile} open={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
        {profile ? (
          <DrawerMenu profile={profile} setIsSettingsOpen={setIsSettingsOpen} open={open} setOpen={setOpen} />
        ) : null}
        {loading ? (
          <LinearProgress
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              left: 0,
            }}
          />
        ) : null}
        <Box display={'flex'} flexDirection={'column'} width={1 / 1}>
          <Toolbar sx={{ display: onCreatePassword ? 'none' : null }}>
            <IconButton sx={{ mr: 1, display: isBreakpoint600 ? 'flex' : 'none' }} onClick={() => setOpen(true)}>
              <MenuIcon />
            </IconButton>
            <BreadcrumbsToolbar />
          </Toolbar>
          <Box sx={{ flex: 1 }}>{children}</Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <NoSsr>
        <HelpButton />
      </NoSsr>
      {content}
    </>
  );
};
