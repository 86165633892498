'use client';
import { BreakpointsOptions, Components, PaletteOptions, ThemeOptions } from '@mui/material/styles';
import { Sora } from 'next/font/google';

const sora = Sora({ subsets: ['latin'] });

const lightThemePalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#187770',
  },
  secondary: {
    main: '#FFFFFF',
  },
  background: {
    default: '#F4EDDB',
  },
  customGrey: {
    main: '#F4F4F4',
  },
  green: {
    main: '#06D6A0',
  },
  red: {
    main: '#EF476F',
  },
  yellow: {
    main: '#FFD166',
  },
  ghosted: {
    main: 'rgba(0, 0, 0, 0.07)',
  },
  subtle: {
    main: 'rgba(0, 0, 0, 0.4)',
  },
  darkBlue: {
    main: '#263D9A',
  },
};

const darkThemePallette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#86B299',
  },
  secondary: {
    main: '#000000',
  },
  background: {
    default: '#000000',
  },
  customGrey: {
    main: '#272727',
  },
  green: {
    main: '#06D6A0',
  },
  red: {
    main: '#EF476F',
  },
  yellow: {
    main: '#FFD166',
  },
  ghosted: {
    main: 'rgba(255, 255, 255, 0.07)',
  },
  subtle: {
    main: 'rgba(255, 255, 255, 0.4)',
  },
  darkBlue: {
    main: '#263D9A',
  },
};

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    xssm: 360,
    ssm: 470,
    sm: 600,
    smd: 740,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

const components: Components = {
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 16,
      },
    },
  },
};

const typography = {
  fontFamily: sora.style.fontFamily,
};

export const lightThemeOptions: ThemeOptions = {
  palette: lightThemePalette,
  breakpoints,
  components,
  typography,
};
export const darkThemeOptions: ThemeOptions = {
  palette: darkThemePallette,
  breakpoints,
  components,
  typography,
};
