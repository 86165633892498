import { SupabaseClient } from '@supabase/supabase-js';

import { Languages } from '@/app/i18n/settings';
import { Database } from '@/types/database.types';
import { Theme_enum } from '@/types/exported.types';

export const getProfileWithRole = async (supabaseClient: SupabaseClient<Database>, userId: string) => {
  const { data: userProfileJunction, error: userProfileJunctionError } = await supabaseClient
    .from('user_profile_junction')
    .select('*, profile(*, role(*), customer(*))')
    .eq('user_id', userId)
    .limit(1)
    .single();

  if (userProfileJunctionError || !userProfileJunction || !userProfileJunction.profile) {
    return { data: null, error: userProfileJunctionError || new Error('No profile found') };
  }

  return { data: userProfileJunction.profile, error: null };
};

export const getRoles = async (supabaseClient: SupabaseClient<Database>) => {
  const role = await supabaseClient.from('role').select('*');
  return role;
};

export const getProfile = async (supabaseClient: SupabaseClient<Database>, userId: string) => {
  const { data: userProfileJunction, error: userProfileJunctionError } = await supabaseClient
    .from('user_profile_junction')
    .select('*, profile(*)')
    .eq('user_id', userId)
    .limit(1)
    .single();

  if (userProfileJunctionError || !userProfileJunction || !userProfileJunction.profile) {
    return { data: null, error: userProfileJunctionError || new Error('No profile found') };
  }

  return { data: userProfileJunction.profile, error: null };
};

export const updateProfile = async (
  supabaseClient: SupabaseClient<Database>,
  profileId: string,
  updates: {
    theme_preference?: Theme_enum;
    language?: Languages;
    name?: string | undefined;
    email_address?: string | undefined;
    phone_number?: number | undefined;
    phone_number_country_code?: string | undefined;
    customer_id?: string | undefined;
    newRoleId?: string | undefined;
  }
) => {
  const { newRoleId, ...rest } = updates;

  const { data: profile, error: profileError } = await supabaseClient.from('profile').update(rest).eq('id', profileId);
  const { data: role, error: roleError } = await supabaseClient
    .from('profile_role_junction')
    .update({ role_id: newRoleId })
    .eq('profile_id', profileId);
  const error = profileError?.message || roleError?.message || null;
  return { profile, role, error: error };
};

export const getProfiles = async (supabaseClient: SupabaseClient<Database>) => {
  const users = await supabaseClient.from('profile').select('*');
  return users;
};

export const getAllOrganizations = async (supabaseClient: SupabaseClient<Database>) => {
  const organizations = await supabaseClient.from('organization').select('*');
  return organizations;
};
